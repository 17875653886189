exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-expertises-apps-tsx": () => import("./../../../src/pages/expertises/apps.tsx" /* webpackChunkName: "component---src-pages-expertises-apps-tsx" */),
  "component---src-pages-expertises-index-tsx": () => import("./../../../src/pages/expertises/index.tsx" /* webpackChunkName: "component---src-pages-expertises-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projecten-tassie-tsx": () => import("./../../../src/pages/projecten/tassie.tsx" /* webpackChunkName: "component---src-pages-projecten-tassie-tsx" */),
  "component---src-pages-projecten-teasy-tsx": () => import("./../../../src/pages/projecten/teasy.tsx" /* webpackChunkName: "component---src-pages-projecten-teasy-tsx" */)
}

